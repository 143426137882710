<template>
  <div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <el-card class="main-chart-container">
        <div class="entity-navbar">
          <div class="left">
            <p class="entity-title">{{ getFieldLabel }}</p>
          </div>
          <div class="right d-flex">
            <el-select
              v-model="showFields.board_field"
              placeholder="Select board field"
              style="width: 100%"
            >
              <el-option
                v-for="(field, i) in getEntityFieldsByType(['SELECT'])"
                :key="field.key + 'chart' + i"
                :label="field.label"
                :value="field.key"
                >{{ field.label }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="chart-main-container">
          <el-scrollbar>
            <div
              class="pie-body"
              v-if="getSelectedFieldOptions && getSelectedFieldOptions.length"
            >
              <div
                v-for="(col, i) in getSelectedFieldOptions"
                :key="i + 'column' + col"
                class="board-column"
              >
                <div class="board-column-head">
                  <div>
                    <el-tag :type="tagType[Math.floor(i % tagType.length)]">
                      {{ getSelectedFieldLabels[i] | truncate(30, "...") }}
                    </el-tag>
                  </div>
                  <div class="count-circle">
                    <div>{{ col.length }}</div>
                  </div>
                </div>
                <draggable
                  :list="col"
                  style="
                    height: 65vh;
                    overflow-y: auto;
                    scrollbar-width: thin;
                    padding: 0 10px 0 0;
                    overflow-x: hidden;
                  "
                  group="people"
                  :disabled="!checkPerimission('EDIT')"
                >
                  <div
                    v-for="data in col"
                    :key="data._id"
                    class="board-card-body"
                    @drop="checkEntityDataSwipe(data, i, $event)"
                  >
                    <h5 @click="viewWorkUserData(data)">
                      {{ getLabel(data) | truncate(20, "...") }}
                    </h5>
                    <div v-for="row in showFields.BoardViewFields" :key="row">
                      <span>{{
                        getFieldValue(row, data) | truncate(20, "...")
                      }}</span>
                    </div>
                    <template
                      v-if="
                        showFields.Board_action_group_button &&
                        showFields.Board_action_group_button.length
                      "
                    >
                      <div
                        v-for="(
                          action, index
                        ) in showFields.Board_action_group_button"
                        :key="index"
                      >
                        <a
                          href="javascript:void(0);"
                          class="card-link"
                          @click="
                            onActionButtonOpen(
                              {
                                template_id: action.split('#')[0],
                                id: action.split('#')[1],
                              },
                              data
                            )
                          "
                          style="
                            color: rgb(64, 158, 255);
                            text-decoration: none;
                          "
                        >
                          {{ getActionField(action) }}
                        </a>
                      </div>
                    </template>
                  </div>
                </draggable>
              </div>
            </div>
            <div class="entity-empty" v-else>
              <el-empty :description="getDescription"></el-empty>
            </div>
          </el-scrollbar>
        </div>
      </el-card>
    </div>

    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import draggable from "vuedraggable";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import axios from "@/config/axios";

export default {
  data() {
    return {
      selectedField: "",
      tagType: ["success", "info", "", "warning", "danger"],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            label: "",
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      componentData: {},

      baseColor: "#65cff0",
      loading: false,
      entity_id: "",
      currentEntity: {},
      loadingText: "Gathering data...",
      refresh: false,
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
      ],
      allEntityDataList: [],
      getSelectedFieldOptions: [],
      getSelectedFieldLabels: [],
      getStandardSelectedFieldOptions: [],
      customDashboardBoardStats: null,
      entityFields: [],
      selectedEntityFields: [],
      quickActionModal: false,
      quickActionDummyField: null,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "geCustomDashboardPieStats",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    getFieldLabel() {
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (field?.label) {
            return field.label;
          }
          return this.currentEntity?.name;
        }
        return this.currentEntity?.name;
      }
      return this.currentEntity?.name;
    },
    getDescription() {
      if (!this.showFields.board_field) {
        return "Field not selected...";
      }
      return "No options...";
    },
    getCount() {
      return (col) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let count = 0;
          let [temp, key] = this.showFields.board_field.split("#");
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              count++;
            }
          });
          return count;
        }
        return 0;
      };
    },
    getCards() {
      return (col) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let result = [];
          let [temp, key] = this.showFields.board_field.split("#");
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              result.push(data);
            }
          });
          return result;
        }
        return [];
      };
    },
  },
  mixins: [
    NavigationHelper,
    EntitiesHelper,
    FieldsFormatHelper,
    CustomDashboardConfig,
  ],
  components: {
    draggable,
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  watch: {
    "showFields.board_field": {
      handler: function (newValue, oldValue) {
        if (oldValue !== newValue) {
          this.loadBoardData();
        }
      },
      deep: true,
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.showFields = { ...this.showFields };
        }
      },
      deep: true,
    },
  },
  props: [
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "getSelfEntityFilters",
  ],
  mounted() {
    this.entity_id = this.$route.params.entity_id || this.entityId;
    if (this.$route.query.board_field) {
      this.showFields.board_field = this.$route.query.board_field;
    }
    this.getNecessaryInfo();
  },
  methods: {
    checkEntityDataSwipe(data, col) {
      this.getSelectedFieldOptions.forEach((column, i) => {
        if (i !== col) {
          (column || []).forEach((card) => {
            if (
              card &&
              data &&
              card._id === data._id &&
              this.showFields?.board_field &&
              this.showFields.board_field.includes("#")
            ) {
              this.updateEntityData(this.getSelectedFieldLabels[i], data);
            }
          });
        }
      });
    },
    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.currentMenu &&
          (this.currentMenu.entity_id || this.currentMenu.relational_entity)
        ) {
          if (access == "UPDATE") {
            access = "EDIT";
          }
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id
              ? this.currentMenu.entity_id._id
              : this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].permissions &&
              this.getEntityPermission.other_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id
              ? this.currentMenu.relational_entity._id
              : this.currentMenu.relational_entity;
            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId]
                .permissions &&
              this.getEntityPermission.relational_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          }
          return false;
        }
        return true;
      } else {
        if (
          this.entityFiltersData?.permissions?.access_set &&
          this.entityFiltersData?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          if (access == "IMPORT" || access == "EXPORT") {
            return true;
          }
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) != -1
          );
        }
        return true;
      }
    },
    async updateEntityData(selectedData, entityData) {
      let [temp, key] = this.showFields.board_field.split("#");
      if (entityData?.entityData && temp && key) {
        let form = entityData.entityData[temp];
        form[key] = selectedData;
        let params = {
          template_data: form,
          entity_data_id: entityData._id,
          template_id: temp,
          entity_id: entityData.entity_id,
        };
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getFieldValue(item, col) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      let form = col.entityData[item.split("#")[0]];
      if (field) {
        let data = this.getFormattedFieldData(field, form);
        return data;
      }
    },
    getActionField(item) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      return field.orgLabel;
    },
    resetChartData() {
      this.chartData.labels = [];
      this.chartData.datasets = [
        {
          backgroundColor: [],
          data: [],
          label: "",
        },
      ];
    },
    async viewWorkUserData(row) {
      if (row._id) {
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/entity/view/${this.entity_id}`,
            query: { ...query, dataId: row._id, filter: this.currentFilter },
          });
        }
      }
    },
    async updateRouterParams() {
      let query = this.getNavigationQuery(this.$route.query);
      query["board_field"] = this.showFields.board_field;
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
    },
    async loadBoardData(filters = []) {
      if (this.showFields.board_field) {
        this.loading = true;
        if (
          this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
          this.isApplicationUserSide
        ) {
          filters = [...filters, ...this.getSelfEntityFilters];
        }
        this.loadingText = "Gathering " + this.currentEntity?.name + " data";
        let params = {
          entity_id: this.entity_id,
          template_fields_data: (this.currentEntity.templates || []).map(
            (template) => {
              return {
                template_id: template.template_id,
              };
            }
          ),
          filters: filters,
          include_contact_profile: true,
        };

        // await this.$store.dispatch(
        //   "entities/fetchEntityRecordsForTable",
        //   params
        // );
        const response = await axios.post("/entities-data/entity/data", params);
        this.customDashboardBoardStats = { ...response.data };
        if (
          this.customDashboardBoardStats &&
          this.customDashboardBoardStats.data
        ) {
          this.allEntityDataList = this.customDashboardBoardStats.data;
          await this.filterEntityData(this.allEntityDataList);
        }
        this.loading = false;
      }
    },
    filterEntityData() {
      this.getSelectedFieldOptions = [];
      this.getSelectedFieldLabels = [];
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (
            field?.inputType &&
            field.inputType == "SELECT" &&
            field.options
          ) {
            field.options.forEach((op) => {
              this.getSelectedFieldOptions.push(this.getCards(op));
              this.getSelectedFieldLabels.push(op);
              this.getStandardSelectedFieldOptions.push(this.getCards(op));
            });
          }
        }
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entity_id,
      });
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        this.loadBoardData();
      }
      this.entityFields = await this.fetchEntityDetails(
        this.getEntityDataById._id,
        true
      );
      this.selectedEntityFields = [];
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        if (this.currentEntity?.views_configuration) {
          this.showFields = { ...this.currentEntity.views_configuration };
        } else if (this.showFieldsParent) {
          this.showFields = { ...this.showFieldsParent };
        }
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              let dt = JSON.parse(JSON.stringify(e.templateInfo));
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(dt),
              ];
            }
          });
        }
      }
      this.loading = false;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates
        ? this.currentEntity.templates
        : []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (!key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (!key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
  },
};
</script>
<style scoped lang="scss">
.main-chart-container {
  width: 100% !important;
  .entity-navbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 5px;
  }
  .left {
    .entity-title {
      color: #0e1216;
      font-weight: 500;
      font-size: 18px;
      margin-top: 5px;
    }
    order: 1;
  }

  .right {
    order: 2;
  }
  .chart-main-container {
    margin-top: 5px;
    .pie-body {
      display: flex !important;
      gap: 10px;
      max-height: 71vh;
      .board-column {
        width: 20%;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        .board-column-head {
          display: flex !important;
          text-align: center;
          .count-circle {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            border: 1px solid #e6e6e6;
            margin-left: 3px;
            margin-top: 3px;
            font-size: 10px;
            background-color: var(--primary-color);
            color: var(--primary-contrast-color);
            div {
              margin-top: 5px !important;
            }
          }
        }
        .board-card-body {
          cursor: pointer;
          width: 100%;
          height: auto;
          background: #ffffff;
          border: 0.5px solid #ebeef5;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2% 0%;
          flex-direction: column;
        }

        .board-card-body:hover {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: lightslategray;
          color: white;
        }
        .board-card-body:active {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: var(--lighter-primary-color);
          color: var(--primary-contrast-color);
        }
      }
    }
  }
}
</style>

<style scoped>
.el-card {
  border-radius: 4px;
  border: 0px solid #ebeef5;
  background-color: transparent;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.el-card.is-always-shadow {
  box-shadow: none;
}
</style>
